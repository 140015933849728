import React from 'react'
import { Link } from 'gatsby'

import '../components/globalStyle.css'
import '../components/product.css'

import Header from '../components/header'
import BackButton from '../components/backButton'


const productEthereumMap = () => (
  <div className="productEthereumGroup">

    <Header />

    <BackButton />

    <div className="product">

      <div className="productText">

        <h2> Ethereum Map </h2>
        <p className="productInfo">
          <i>
            Nov 2018 - March 2019<br></br>
            Created under mentorship of TJ Blanchflower<br></br>
          </i>
        </p>

        <p>
          The Ethereum Blockchain is notoriously complicated. But, it is also extremely intentional. The system embodies a clear philosophical end based in security through radical transparency and anonymity. It is undeniable the new crypto users face a quagmire of horrible UX to get anything done. But these complications all serve a purpose.
          <br></br>
          <br></br>
          Designers for blockchain apps should be mindful of intent before removing necessary blockades. Ethereum users should understand what they are giving up and gaining by participating. In this project, I dug deep into technical details, looking for the reasons behind the system's UX issues. I then produced artifacts that analyzed the difficulties of the Ethereum Blockchain and why they matter.
        </p>
      </div>

      <figure className="productImg">
        <img src={require('../img/ethereumMap/mapWhole.jpg')} />
        <figcaption>I created a concept map that dives deep into almost every detail of the Ethereum Blockchain. I go over how it works, why it exists, and how it is related to all the other parts that comprise the system.</figcaption>
      </figure>

      <div className="mapDetails">
        <figure className="productImg">
          <img src={require('../img/ethereumMap/mapDetail1.jpg')} />
          <figcaption>This is a detail of the map where I break down what a "node" is.</figcaption>
        </figure>

        <figure className="productImg">
          <img src={require('../img/ethereumMap/mapDetail2.jpg')} />
          <figcaption>This is a detail of the map where I break down what a "miner" does.</figcaption>
        </figure>
      </div>

      <figure className="productImg">
        <img src={require('../img/ethereumMap/mapAnimation.jpg')} />
        <figcaption>These are screenshots from an animation I made following a transaction step by step in the context of all the system's moving parts. While the information presented is very accurate, it was important that it was also easy to understand.</figcaption>
      </figure>

      <figure className="productImg">
        <img src={require('../img/ethereumMap/mapInfographic.svg')} />
        <figcaption>These infographics supplement the animation, going into more depth about particularly important topics. They explain how perceived technical and UX constraints are necessary to uphold the philosophical integrity that Ethereum is built on.</figcaption>
      </figure>



      <div className="productText">
        <h4>Moving on</h4>
        <p>
          I was unfortunately laid off at ConsenSys due to restructuring while this work was ongoing. I've left these documents in progress for my fellow designers to access, and I hope someone takes on this work :)
        </p>
      </div>
    </div>

  </div>


)


export default productEthereumMap
